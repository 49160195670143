.ExternalLink {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: inherit;
  transition: opacity 0.2s;
  opacity: 0.6;
}

a.ExternalLink:hover {
  opacity: 1;
}

.ExternalLink svg {
  margin-right: 1rem;
  flex-shrink: 0;
}
