* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  position: relative;
}

body {
  background: white;
  font-family: Helvetica, sans-serif;
}
