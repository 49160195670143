.Portfolio {
  position: relative;
  margin-top: 50vh;
  width: 100%;
  font-size: 1rem;
  line-height: 200%;
}

.Portfolio .Section {
  display: flex;
}

.Portfolio .Header {
  position: sticky;
  top: 2rem;
  z-index: 2;
  margin-bottom: 2rem;
  align-self: flex-start;
  width: 25%;
  text-align: right;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.Portfolio .Header .Header-small {
  display: none;
}

.Portfolio .Header h1,
.Portfolio .Header p,
.Portfolio .Header span,
.Portfolio .Items h1,
.Portfolio .Items p {
  font-size: 1rem;
  line-height: 150%;
}

.Portfolio .Items {
  width: 75%;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.Portfolio .Item {
  margin-bottom: 2rem;
}

.Portfolio .Item-links {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Portfolio .Item-description {
  max-width: 38em;
}

.Portfolio .Item-stack {
  margin-top: 0.2em;
}

@media (max-width: 600px) {
  .Portfolio .Section {
    display: block;
  }

  .Portfolio .Header {
    width: 100%;
    top: 0;
    text-align: left;
    padding: 0;
    margin-bottom: 0;
  }

  .Portfolio .Header .Header-small {
    display: flex;
    width: 100%;
  }

  .Portfolio .Header .Header-small .period {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25vw;
    padding: 1rem;
  }

  .Portfolio .Header .Header-small .details {
    padding-left: 1rem;
    padding-right: 1rem;
    padding: 1rem;
    flex: 1;
  }

  .Portfolio .Header .Header-large {
    display: none;
  }

  .Portfolio .Items {
    /* width: 100%; */
    padding: 1rem;
    margin-left: 25vw;
  }

  .Content-layer-B .Portfolio .Header-small .period {
    /* HACK */
    visibility: hidden;
  }
}
