.Navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

.Navigation-cross {
  line-height: 0;
}

.Navigation-logo {
  position: absolute;
  bottom: 50%;
  right: 50vw;
  width: 252px;
  height: 200px;
  transform: translate(15px, 15px);
  pointer-events: none;
}

.Navigation-links {
  position: absolute;
  top: 50%;
  left: 50%;
  pointer-events: auto;
}

.Navigation-links a {
  position: absolute;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
  color: inherit;
}

.Navigation-links a:nth-child(1) {
  margin-top: -0.5em;
  margin-left: 2em;
}

.Navigation-links a:nth-child(2) {
  margin-top: 2em;
  margin-left: 0.5em;
  transform: rotate(90deg);
  transform-origin: 0% 0%;
}

@media (max-width: 600px) {
  .Navigation {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .Navigation-logo {
    width: 100%;
    padding: 2rem;
    height: auto;
    bottom: 50vh;
    right: 50vw;
    transform: translate(50%, 50%);
  }

  .Navigation-links {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .Navigation-links a,
  .Navigation-links a:nth-child(1),
  .Navigation-links a:nth-child(2) {
    margin: 0;
    padding: 2rem;
  }

  .Navigation-links a:nth-child(1) {
    top: 0;
    left: 0;
  }

  .Navigation-links a:nth-child(2) {
    bottom: 0;
    right: 0;
    transform: none;
  }
}
