.Profile {
  position: absolute;
  top: 25%;
  left: 0;
  width: 100%;
}

.Profile-content {
  position: relative;
  margin-top: -2em;
  padding-left: 50vw;
  margin-right: 3em;
  margin-bottom: 4rem;
}

.Profile .details h1,
.Profile .details h2,
.Profile .details li,
.Profile .summary p {
  font-size: 1rem;
  line-height: 200%;
  max-width: 32rem;
}

.Profile .details h1,
.Profile .details h2 {
  text-transform: uppercase;
}

.Profile .details h2 {
  margin-bottom: 2rem;
}

.Profile .summary p {
  margin: 0;
  margin-bottom: 1.5em;
  padding: 0;
}

.Profile .links {
  position: absolute;
  top: 6rem;
  left: 25vw;
  list-style-type: none;
  margin-bottom: 2rem;
  transform: translateX(-50%);
}

.Profile .Logo {
  margin-top: 1rem;
  width: 3rem;
}

@media (max-width: 600px) {
  .Profile-content {
    padding-left: 40vw;
    margin-right: 2em;
  }

  .Profile .links {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
}
