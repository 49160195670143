.App {
  position: absolute;
}

.App-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Animate clip-paths */

.App-background,
.Content-layer-A,
.Content-layer-B,
.Navigation-layer-A,
.Navigation-layer-B {
  transition: clip-path 0.1s;
  transition-timing-function: ease-out;
}

/* Home */

.App-route-home .App-background,
.App-route-home .Content-layer-A,
.App-route-home .Navigation-layer-A {
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 100%, 0% 100%);
}

.App-route-home .Content-layer-B,
.App-route-home .Navigation-layer-B {
  clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
}

/* Portfolio */

.App-route-portfolio .App-background,
.App-route-portfolio .Content-layer-A,
.App-route-portfolio .Navigation-layer-A {
  clip-path: polygon(0% 0%, 25% 0%, 25% 0%, 25% 100%, 0% 100%);
}

.App-route-portfolio .Content-layer-B,
.App-route-portfolio .Navigation-layer-B {
  clip-path: polygon(25% 0%, 100% 0%, 100% 1000%, 25% 1000%);
}

@media (max-width: 600px) {
  .App-route-portfolio .App-background,
  .App-route-portfolio .Content-layer-A,
  .App-route-portfolio .Navigation-layer-A {
    clip-path: polygon(0% 0%, 25% 0%, 25% 0%, 25% 1000%, 0% 1000%);
  }

  .App-route-portfolio .Content-layer-B,
  .App-route-portfolio .Navigation-layer-B {
    clip-path: polygon(25% 0%, 100% 0%, 100% 1000%, 25% 1000%);
  }
}

/* Profile */

.App-route-profile .App-background,
.App-route-profile .Content-layer-A,
.App-route-profile .Navigation-layer-A {
  clip-path: polygon(0% 0%, 100% 0%, 100% 25%, 0% 25%, 0% 25%);
}

.App-route-profile .Content-layer-B,
.App-route-profile .Navigation-layer-B {
  clip-path: polygon(0% 25%, 100% 25%, 100% 1000%, 0% 1000%);
}

/* Animate page-content */

.Profile,
.Portfolio {
  display: none;
  opacity: 0;
}

.App-route-profile .Profile,
.App-route-portfolio .Portfolio {
  display: block;
  opacity: 1;
  /* animation: fadeIn 0.5s; */
}

.App-route-portfolio .Navigation,
.App-route-profile .App-background,
.App-route-profile .Navigation {
  position: absolute;
}

/* Animate Navigation-links */

.Navigation-links {
  transition: opacity 0.1s;
  opacity: 1;
  pointer-events: auto;
}

.App-route-portfolio .Navigation-links,
.App-route-profile .Navigation-links {
  opacity: 0;
  pointer-events: none;
}

/* Animate Navigation-logo */

.Navigation-logo {
  opacity: 1;
  transition: opacity 0.2s;
}

.Navigation-cross {
  position: absolute;
  pointer-events: none;
  left: 25vw;
  top: 25%;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s ease-out;
  opacity: 0;
}

.Navigation-cross g {
  stroke-width: 0;
  transition: stroke-width 0.1s ease-out;
}

.App-route-portfolio .Navigation-logo,
.App-route-profile .Navigation-logo {
  opacity: 0;
}

.App-route-portfolio .Navigation-cross,
.App-route-profile .Navigation-cross {
  pointer-events: auto;
  opacity: 1;
}

.App-route-portfolio .Navigation-cross g,
.App-route-profile .Navigation-cross g {
  stroke-width: 3;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
